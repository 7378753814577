<template>
  <div>
    <h1>Funktionärer</h1>
    <AddFunctionToMember />
  </div>
</template>

<script>
  import AddFunctionToMember from '@/components/AddFunctionToMember.vue'

  export default {
    components: { AddFunctionToMember }
  }
</script>
