<template>
  <div>
    <b-form inline>
      <b-form-input class="mr-2" name="person" placeholder="person" v-model="currentPersonName" />
      <b-form-select class="mr-2" name="function" placeholder="Funktionärsroll" v-model="selectedFunction" :options="functions"/>
      <b-button v-b-modal.select-person-modal class="m-3">
	<span v-if="person">Byt person</span>
	<span v-else>Välj person</span>
      </b-button>
      <b-button variant="primary" @click="addMemberFunction()">Spara</b-button>
    </b-form>
    <SelectPersonModal id="select-person-modal" @person="setPerson" :allowChangingPeriod="true" />
  </div>
</template>

<script>
import { arrayUnion, doc, updateDoc } from 'firebase/firestore'
import { db } from '@/firebaseConfig.js'
import SelectPersonModal from '@/components/SelectPersonModal.vue'

export default {
  components: { SelectPersonModal },
  data() {
    return {
      selectedFunction: undefined,
      person: undefined,
      functions: [
        { value: 'inst', text: 'instruktör', disabled: this.isInstructor() },
        { value: 'styr', text: 'styrelsemedlem', disabled: this.isStyrelse() },
        { value: 'kordf', text: 'kommittéordf.', disabled: this.isKommitteordf() }
      ]
    }
  },
  computed: {
    currentPersonName() {
      return this.person ? `${this.person.firstName} ${this.person.lastName}: ${this.person.id}` : ''
    }
  },
  methods: {
    setPerson(person) {
      this.person = person
      this.functions = [
        { value: 'inst', text: 'instruktör', disabled: this.isInstructor(person) },
        { value: 'styr', text: 'styrelsemedlem', disabled: this.isStyrelse(person) },
        { value: 'kordf', text: 'kommittéordf.', disabled: this.isKommitteordf(person) }
      ]
    },
    addMemberFunction() {
      updateDoc(doc(db, 'members', this.person.id), { functions: arrayUnion(this.selectedFunction) })
        .then(() => console.log('Member updated'))
        .catch((error) => console.log(`Failed to update: ${error}`))
    },
    personHasFunction(person, funct) {
      person && person.functions && person.functions.includes(funct)
    },
    isInstructor(person) {
      return !!this.personHasFunction(person, 'inst')
    },
    isStyrelse(person) {
      return !!this.personHasFunction(person, 'styr')
    },
    isKommitteordf(person) {
      return !!this.personHasFunction(person, 'kordf')
    }
  }
}
</script>
